import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import IvykioAplinkybes from './IvykioAplinkybes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseDamage } from '@fortawesome/free-solid-svg-icons';
import Patvirtinimas from './Patvirtinimas';
import { toast } from 'react-toastify';
import apiRequest from '../utils/apiRequest';
import { useLocation, useNavigate } from "react-router-dom";
import DropzoneStyled from "./Dropzone";
import getUser from '../utils/getUser';
import ListFiles from './listFiles';

export default function Turtas(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [failai, setFailai] = useState([]);
    const [isSaving, setSaving] = useState(false);

    const onSubmit = (data, e) => {
        setSaving(true);
        data.dokumentai = failai;
        return apiRequest("turtas", data).then(() => {
            setSaving(false);
            toast.success("Dėkojame. Jūsų pranešimas yra sėkmingai pateiktas. Esant poreikiui patikslinti informaciją, atsakingas ekspertas su Jumis susisieks.");
            navigate("/");
        }).catch((e) => {
            setSaving(false);
            toast.error(e.message ? e.message : e);
        });
    }

    const handleDokumentaiUpload = (files) => {
        setFailai(files.concat(failai));
        setSaving(false);
    };

    const handleDrop = () => {
        setSaving(true);
    }

    const { register, handleSubmit, watch, setValue, getValues, formState: {errors} } = useForm();
    const watchTurtasPriklauso = watch("turtasPriklauso", {});
    const watchZinomasKaltininkas = watch("zinomasKaltininkas");
    const [user, setUser] = useState();

    useEffect(() => {
        if (location.search) {
            if (!user) {
                getUser(location.search).then(data => {
                    if (data) {
                        setUser(data);
                        setValue("pranesancioPavardeVardas", data.pavadinimas);
                        setValue("pranesancioTelefonas", data.telefonas);
                        setValue("pranesancioElPastas", data.elPastas);
                        if (data.imones && data.imones.length) {
                            setValue("imonesPavadinimas", data.imones[0].pavadinimas);
                        }
                    }
                });
            }
            else if (!getValues("imonesPavadinimas" && user && user.imones && user.imones.length)) {
                setValue("imonesPavadinimas", user.imones[0].pavadinimas);
            }
        }
    });

    return (
        <React.Fragment>
            <div className="page-header">
                <h1><FontAwesomeIcon icon={faHouseDamage} /> Turto draudimo įvykis</h1>
                <span className="remark">* pažymėti laukai yra privalomi</span>
            </div>
            <form className="form-30" onSubmit={handleSubmit(onSubmit)}>
                <h5>Informacija apie pranešėją</h5>

                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Žala registruojama pagal draudimo sutartį</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchTurtasPriklauso === "Juridinio asmens" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="Juridinio asmens" {...register("turtasPriklauso", { required: true })} className="radio-button" />Juridinio asmens
                        </label>
                            <label className={watchTurtasPriklauso === "Fizinio asmens" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="Fizinio asmens" {...register("turtasPriklauso", { required: true })} className="radio-button" />Fizinio asmens
                        </label>
                            {errors.turtasPriklauso && <span className="error">Reikia pasirinkti vieną iš dviejų</span>}
                        </div>
                    </div>
                </div>

                {watchTurtasPriklauso === "Juridinio asmens" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Įmonės (draudėjo) pavadinimas</label>
                    <div className="col-lg-8">
                        <input className={errors.imonesPavadinimas && watchTurtasPriklauso === "Juridinio asmens" ? "form-control error" : "form-control"} {...register("imonesPavadinimas", { validate: value => value && watchTurtasPriklauso === "Juridinio asmens" })} />
                        {errors.imonesPavadinimas && watchTurtasPriklauso === "Juridinio asmens" && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}

                {watchTurtasPriklauso === "Fizinio asmens" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Draudėjo (asmens, kurio vardu sudaryta draudimo sutartis) vardas pavardė</label>
                    <div className="col-lg-8">
                        <input className={errors.pavardeVardas && watchTurtasPriklauso === "Fizinio asmens" ? "form-control error" : "form-control"} {...register("pavardeVardas", { validate: value => value && watchTurtasPriklauso === "Fizinio asmens" })} />
                        {errors.pavardeVardas && watchTurtasPriklauso === "Fizinio asmens" && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}

                {watch("turtasPriklauso") && <div className="form-group row">
                    {watch("turtasPriklauso") === "Juridinio asmens" && <label className="col-lg-4 col-form-label">Įmonės (draudėjo) kodas</label>}
                    {watch("turtasPriklauso") === "Fizinio asmens" && <label className="col-lg-4 col-form-label">Asmens (draudėjo) kodas</label>}
                    <div className="col-lg-8">
                        <input className={errors.kodas ? "form-control error" : "form-control"} {...register("kodas")} />
                    </div>
                </div>}

                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Pranešėjo vardas pavardė</label>
                    <div className="col-lg-8">
                        <input className={errors.pranesancioPavardeVardas ? "form-control error" : "form-control"} {...register("pranesancioPavardeVardas", { required: true })} />
                        {errors.pranesancioPavardeVardas && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Pranešėjo telefono numeris</label>
                    <div className="col-lg-8">
                        <input type="tel" className={errors.pranesancioTelefonas ? "form-control error" : "form-control"} {...register("pranesancioTelefonas", { required: true })} />
                        {errors.pranesancioTelefonas && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Pranešėjo el. pašto adresas</label>
                    <div className="col-lg-8">
                        <input type="email" className={errors.pranesancioElPastas ? "form-control error" : "form-control"} {...register("pranesancioElPastas", { required: true })} />
                        {errors.pranesancioElPastas && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>

                <hr />
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Draudimo liudijimo Nr.</label>
                    <div className="col-lg-8">
                        <input className="form-control" {...register("liudijimoNr")} />
                    </div>
                </div>

                <hr />
                <h5>Informacija apie įvykį</h5>

                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Įvykio data ir laikas</label>
                    <div className="col-lg-8">
                        <div className="input-group">
                            <input className={errors.ivykioData ? "form-control error" : "form-control"} type="date" {...register("ivykioData", { required: true })} />
                            <input className={errors.ivykioLaikas ? "form-control error" : "form-control"} type="time" {...register("ivykioLaikas", { required: true })} />
                        </div>
                        {(errors.ivykioData || errors.ivykioLaikas) && <span className="error">Šie laukai yra privalomi</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Įvykio vieta</label>
                    <div className="col-lg-8">
                        <input className={errors.ivykioVieta ? "form-control error" : "form-control"} {...register("ivykioVieta", { required: true })} />
                        {errors.ivykioVieta && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                <IvykioAplinkybes watch={watch} register={register} errors={errors} />
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Ar žinomas įvykio kaltininkas?</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchZinomasKaltininkas === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="taip" {...register("zinomasKaltininkas", { required: true })} className="radio-button" />
                                Taip</label>
                            <label className={watchZinomasKaltininkas === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="ne" {...register("zinomasKaltininkas", { required: true })} className="radio-button" />
                                Ne</label>
                            {errors.zinomasKaltininkas && <span className="error">Reikia pasirinkti</span>}
                        </div>
                    </div>
                </div>
                {watchZinomasKaltininkas === "taip" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Informacija apie kaltininką</label>
                    <div className="col-lg-8">
                        <input className={errors.kaltininkas ? "form-control error" : "form-control"} {...register("kaltininkas", { validate: value => value && watchZinomasKaltininkas === "taip" })} />
                        {errors.kaltininkas && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}

                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Turto sugadinimai, patirti nuostoliai</label>
                    <div className="col-lg-8">
                        <textarea className={errors.nukentejusioTurtoApgadinimai ? "form-control error" : "form-control"} rows="5" {...register("nukentejusioTurtoApgadinimai", { required: true })} />
                        {errors.nukentejusioTurtoApgadinimai && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>

                <hr />
                <h5>Susiję dokumentai</h5>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Dokumentai (pvz. sugadinto turto nuotraukos, įsigijimo dokumentai, policijos, ar kitų tarnybų pažymos)</label>
                    <div className="col-lg-8">
                        <DropzoneStyled
                            handleDrop={handleDrop}
                            handleUpload={handleDokumentaiUpload}
                            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/x-csv"
                            multiple={true}
                            tekstas="Nutempkite failus čia arba paspauskite norėdami failus pasirinkti..."
                        />
                        <ListFiles failai={failai} />
                        <div>Bendras prisegtų dokumentų maksimalus dydis – 500 MB. Leidžiami formatai - .pdf, .jpg, .jpeg, .gif, .png, .doc, .docx, .xls, .xlsx.</div>
                    </div>
                </div>
                <hr />
                <Patvirtinimas register={register} errors={errors} />
                <div className="form-group">
                    <input type="submit" className="btn btn-submit btn-lg btn-block" value="Pateikti" disabled={isSaving} />
                </div>
            </form>
        </React.Fragment>
    )
}

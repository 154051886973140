export default async function apiUpload(body) {
  const headers = new Headers();
  headers.append("Accept", "application/json");

  const options = {
    method: "POST",
    headers,
    body
  };

  const response = await fetch("api/failai/upload", options);
  if (response.status === 200) {
      const data = await response.json();
      return data;
  }
  if (response.status === 204) {
      return;
  }
  const error = await response.json();
  console.log(error);
  throw(response.status, error);
}

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PranesanciojoInformacija from './PranesancioInformacija';
import IvykioAplinkybes from './IvykioAplinkybes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarCrash } from '@fortawesome/free-solid-svg-icons';
import Patvirtinimas from './Patvirtinimas';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";
import DropzoneStyled from "./Dropzone";
import apiRequest from '../utils/apiRequest';
import ListFiles from './listFiles';

export default function Kasko() {
    const location = useLocation();
    const navigate = useNavigate();
    const [isSaving, setSaving] = useState(false);
    const [failai, setFailai] = useState([]);
    const [failas, setFailas] = useState();

    const onSubmit = (data, e) => {
        setSaving(true);
        if (failai && failai.length) {
            data.dokumentai = failai;
        }
        if (failas && failas.originalusPavadinimas) {
            data.samataDerinimui = failas;
        }
        return apiRequest("kasko", data).then(() => {
            setSaving(false);
            toast.success("Dėkojame. Jūsų pranešimas yra sėkmingai pateiktas. Esant poreikiui patikslinti informaciją, atsakingas ekspertas su Jumis susisieks.");
            navigate("/");
        }).catch((e) => {
            setSaving(false);
            toast.error(e.message ? e.message : e);
        });
    }

    const handleDrop = () => {
        setSaving(true);
    }

    const handleDokumentaiUpload = (files) => {
        setFailai(files.concat(failai));
        setSaving(false);
    };

    const handleSamataUpload = (failai) => {
        setFailas(failai[0]);
        setSaving(false);
    };

    const { register, handleSubmit, watch, getValues, setValue, formState: {errors} } = useForm();
    const watchBuvoSuzalotu = watch("buvoSuzalotu");
    const watchDeklaracija = watch("deklaracija");
    const watchNukentejoTurtas = watch("nukentejoTurtas");
    const watchNukentejoTransportas = watch("nukentejoTransportas");
    const watchPageidaujuPakaitinioAutomobilio = watch("pageidaujuPakaitinioAutomobilio");
    const watchPolicija = watch("policija");
    const watchRegistruotiTpvca = watch("registruotiTpvca");
    const watchTransportoPriemonesTipas = watch("transportoPriemonesTipas");
    const watchVairuotojas = watch("vairuotojoTipas");

    return (
        <React.Fragment>
            <div className="page-header">
                <h1><FontAwesomeIcon icon={faCarCrash} /> KASKO draudimo įvykis</h1>
                <span className="remark">* pažymėti laukai yra privalomi</span>
            </div>
            <form className="form-30" onSubmit={handleSubmit(onSubmit)}>
                <PranesanciojoInformacija search={location.search} register={register} watch={watch} getValues={getValues} setValue={setValue} errors={errors} />
                <hr />
                <h5>Transporto priemonė</h5>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Pasirinkite vieną iš variantų</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchTransportoPriemonesTipas === "Registruota transporto priemonė" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="Registruota transporto priemonė" {...register("transportoPriemonesTipas", { required: true })} className="radio-button" />
                                Registruota transporto priemonė</label>
                            <label className={watchTransportoPriemonesTipas === "Mobilioji technika" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="Mobilioji technika" {...register("transportoPriemonesTipas", { required: true })} className="radio-button" />
                                Mobilioji technika</label>
                            {errors.transportoPriemonesTipas && <span className="error">Reikia pasirinkti</span>}
                        </div>
                    </div>
                </div>
                {watchTransportoPriemonesTipas === "Registruota transporto priemonė" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Transporto priemonės valstybinis nr.</label>
                    <div className="col-lg-8">
                        <input className={errors.valstNr ? "form-control error" : "form-control"} {...register("valstNr", { required: true })} />
                        {errors.valstNr && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchTransportoPriemonesTipas === "Registruota transporto priemonė" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Transporto priemonės markė ir modelis</label>
                    <div className="col-lg-8">
                        <input className={errors.marke ? "form-control error" : "form-control"} {...register("marke", { required: true })} />
                        {errors.marke && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchTransportoPriemonesTipas === "Mobilioji technika" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Kėbulo / Identifikacinis / Serijinis / Inventoriaus Nr. </label>
                    <div className="col-lg-8">
                        <input className={errors.inventoriausNr ? "form-control error" : "form-control"} {...register("inventoriausNr", { required: true })} />
                        {errors.inventoriausNr && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchTransportoPriemonesTipas === "Mobilioji technika" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Draudimo objektas</label>
                    <div className="col-lg-8">
                        <input className={errors.draudimoObjektas ? "form-control error" : "form-control"} {...register("draudimoObjektas", { required: true })} />
                        {errors.draudimoObjektas && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                <hr />
                <h5>Informacija apie įvykį</h5>

                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Įvykio data ir laikas</label>
                    <div className="col-lg-8">
                        <div className="input-group">
                            <input className={errors.ivykioData ? "form-control error" : "form-control"} type="date" {...register("ivykioData", { required: true })} />
                            <input className={errors.ivykioLaikas ? "form-control error" : "form-control"} type="time" {...register("ivykioLaikas", { required: true })} />
                        </div>
                        {(errors.ivykioData || errors.ivykioLaikas) && <span className="error">Šie laukai yra privalomi</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Įvykio vieta</label>
                    <div className="col-lg-8">
                        <input className={errors.ivykioVieta ? "form-control error" : "form-control"} {...register("ivykioVieta", { required: true })} />
                        {errors.ivykioVieta && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                <IvykioAplinkybes watch={watch} register={register} errors={errors} />
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Ar buvo sužalotų asmenų?</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchBuvoSuzalotu === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="taip" {...register("buvoSuzalotu", { required: true })} className="radio-button" />
                                Taip</label>
                            <label className={watchBuvoSuzalotu === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="ne" {...register("buvoSuzalotu", { required: true })} className="radio-button" />
                                Ne</label>
                            {errors.buvoSuzalotu && <span className="error">Reikia pasirinkti</span>}
                        </div>
                    </div>
                </div>
                {watchBuvoSuzalotu === "taip" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Nukentėjusių duomenys (vardas, pavardė, kontaktai)</label>
                    <div className="col-lg-8">
                        <textarea className={errors.nukentejusiuDuomenys ? "form-control error" : "form-control"} rows="5" {...register("nukentejusiuDuomenys", { validate: value => value && watchBuvoSuzalotu === "taip" })} />
                        {errors.nukentejusiuDuomenys && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchTransportoPriemonesTipas === "Registruota transporto priemonė" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Ar pildyta eismo įvykio deklaracija?</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchDeklaracija === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="taip" {...register("deklaracija", { required: true })} className="radio-button" />
                                Taip</label>
                            <label className={watchDeklaracija === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="ne" {...register("deklaracija", { required: true })} className="radio-button" />
                                Ne</label>
                            {errors.deklaracija && <span className="error">Reikia pasirinkti</span>}
                        </div>
                    </div>
                </div>}
                {watchTransportoPriemonesTipas === "Registruota transporto priemonė" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Ar įvykis registruotas policijoje?</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchPolicija === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="taip" {...register("policija", { required: true })} className="radio-button" />
                                Taip</label>
                            <label className={watchPolicija === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="ne" {...register("policija", { required: true })} className="radio-button" />
                                Ne</label>
                            {errors.policija && <span className="error">Reikia pasirinkti</span>}
                        </div>
                    </div>
                </div>}
                {watchPolicija === "taip" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Policijos padalinio pavadinimas (miestas/rajonas/apskritis ir įstaiga)</label>
                    <div className="col-lg-8">
                        <input className={errors.policijosPadalinys ? "form-control error" : "form-control"} {...register("policijosPadalinys", { validate: value => value && watchPolicija === "taip" })} />
                        {errors.policijosPadalinys && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Ar vairavote eismo įvykio metu?</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchVairuotojas === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="taip" {...register("vairuotojoTipas", { required: true })} className="radio-button" />
                                Taip</label>
                            <label className={watchVairuotojas === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="ne" {...register("vairuotojoTipas", { required: true })} className="radio-button" />
                                Ne</label>
                            <label className={watchVairuotojas === "nebuvoVairuotojo" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="nebuvoVairuotojo" {...register("vairuotojoTipas", { required: true })} className="radio-button" />
                                Nebuvo vairuotojo</label>
                            {errors.vairuotojoTipas && <span className="error">Būtina pasirinkti</span>}
                        </div>
                    </div>
                </div>
                {watchVairuotojas === "ne" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Vairuotojo vardas pavardė</label>
                    <div className="col-lg-8">
                        <input className={errors.vairuotojoPavardeVardas ? "form-control error" : "form-control"} {...register("vairuotojoPavardeVardas", { validate: value => value && watchVairuotojas === "ne" })} />
                        {errors.vairuotojoPavardeVardas && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchVairuotojas === "ne" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Vairuotojo asmens kodas</label>
                    <div className="col-lg-8">
                        <input className={errors.vairuotojoKodas ? "form-control error" : "form-control"} {...register("vairuotojoKodas", { validate: value => value && watchVairuotojas === "ne" })} />
                        {errors.vairuotojoKodas && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Transporto priemonės apgadinimai</label>
                    <div className="col-lg-8">
                        <textarea className={errors.transportoApgadinimai ? "form-control error" : "form-control"} rows="5" {...register("transportoApgadinimai", { required: true })} />
                        {errors.transportoApgadinimai && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                {watchTransportoPriemonesTipas === "Registruota transporto priemonė" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Pageidauju pakaitinio automobilio</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchPageidaujuPakaitinioAutomobilio === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="taip" {...register("pageidaujuPakaitinioAutomobilio")} className="radio-button" />
                                Taip</label>
                            <label className={watchPageidaujuPakaitinioAutomobilio === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="ne" {...register("pageidaujuPakaitinioAutomobilio")} className="radio-button" />
                                Ne</label>
                            {errors.vairuotojas && <span className="error">Reikia pasirinkti</span>}
                        </div>
                    </div>
                </div>}
                {watchTransportoPriemonesTipas === "Registruota transporto priemonė" && <div className="form-group row">
                    <hr />
                    <label className="col-lg-4 col-form-label">Jei pranešate apie įvykį, už kurį esate atsakingas ir kurio metu nukentėjo ne tik Jūsų transporto priemonė, bet ir trečiųjų  asmenų turtas/sveikata, kartu galite užregistruoti ir žalą pagal privalomąjį TPVCA draudimą</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchRegistruotiTpvca === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="taip" {...register("registruotiTpvca")} className="radio-button" />
                                Registruoti</label>
                            <label className={watchRegistruotiTpvca === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="ne" {...register("registruotiTpvca")} className="radio-button" />
                                Ne, ačiū</label>
                        </div>
                    </div>
                </div>}
                {watchRegistruotiTpvca === "taip" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Eismo įvykio metu nukentėjo</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchNukentejoTransportas === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="checkbox" value="taip" {...register("nukentejoTransportas", { validate: value => value === "taip" || watchNukentejoTurtas === "taip" })} id="nukentejoTransportas" className="radio-button" />
                                Transporto priemonė</label>
                            <label className={watchNukentejoTurtas === "taip" ? "btn btn-gray active" : "btn btn-gray"} title="(pvz. kelio ženklas, tvora, atitvaras, pastatas)">
                                <input type="checkbox" value="taip" {...register("nukentejoTurtas", { validate: value => watchNukentejoTransportas === "taip" || value === "taip" })} id="nukentejoTurtas" className="radio-button" />
                                Kitas turtas</label>
                            {errors.nukentejoTurtas && errors.nukentejoTransportas && <span className="error">Būtina pasirinkti</span>}
                        </div>
                    </div>
                </div>}
                {watchNukentejoTransportas === "taip" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Nukentėjusiojo transporto priemonės valstybinis nr.</label>
                    <div className="col-lg-8">
                        <input className={errors.nukentejusioValstNr ? "form-control error" : "form-control"} {...register("nukentejusioValstNr", { validate: value => value && watchNukentejoTransportas === "taip" })} />
                        {errors.nukentejusioValstNr && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchNukentejoTransportas === "taip" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Nukentėjusiojo transporto priemonės markė ir modelis</label>
                    <div className="col-lg-8">
                        <input className={errors.nukentejusioMarke ? "form-control error" : "form-control"} {...register("nukentejusioMarke", { required: true })} />
                        {errors.nukentejusioMarke && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchNukentejoTransportas === "taip" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Nukentėjusiojo transporto priemonės apgadinimai</label>
                    <div className="col-lg-8">
                        <textarea className={errors.nukentejusioTransportoApgadinimai ? "form-control error" : "form-control"} rows="5" {...register("nukentejusioTransportoApgadinimai", { required: true })} />
                        {errors.nukentejusioTransportoApgadinimai && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchNukentejoTurtas === "taip" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Nukentėjusiojo turto aprašymas ir apgadinimai</label>
                    <div className="col-lg-8">
                        <textarea className={errors.nukentejusioTurtoApgadinimai ? "form-control error" : "form-control"} rows="5" {...register("nukentejusioTurtoApgadinimai", { validate: value => value && watchNukentejoTurtas === "taip" })} />
                        {errors.nukentejusioTurtoApgadinimai && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                <hr />
                <h5>Susiję dokumentai</h5>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Sąmata derinimui</label>
                    <div className="col-lg-8">
                        <DropzoneStyled
                            handleDrop={handleDrop}
                            handleUpload={handleSamataUpload}
                            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/x-csv"
                            multiple={false}
                            tekstas="Nutempkite sąmatos dokumentą čia arba paspauskite norėdami jį pasirinkti... <br /><i>(Čia galite prisegti tik vieną dokumentą. Esant poreikiui prisegti kelis failus, įkelkite likusius į skiltį “Kiti dokumentai”)</i>" />
                        <ListFiles failas={failas} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Kiti dokumentai (eismo įvykio deklaracija, vairuotojo pažymėjimas, transporto priemonės registracijos liudijimas, nuotraukos ir kiti)</label>
                    <div className="col-lg-8">
                        <DropzoneStyled
                            handleDrop={handleDrop}
                            handleUpload={handleDokumentaiUpload}
                            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/x-csv"
                            multiple={true}
                            tekstas="Nutempkite failus čia arba paspauskite norėdami failus pasirinkti..."
                        />
                        <ListFiles failai={failai} />
                        <div>Bendras prisegtų dokumentų maksimalus dydis – 500 MB. Leidžiami formatai - .pdf, .jpg, .jpeg, .gif, .png, .doc, .docx, .xls, .xlsx.</div>
                    </div>
                </div>
                <hr />
                <Patvirtinimas register={register} errors={errors} />
                <div className="form-group">
                    <input type="submit" className="btn btn-submit btn-lg btn-block" value="Pateikti" disabled={isSaving} />
                </div>
            </form>
        </React.Fragment >
    )
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import ivplogo from "../assets/ivp_firminis_spalvotas.png";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <a className="navbar-brand" href="/"><img src={ivplogo} alt="logo" /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active"><Link to="/" className="nav-link">PRADŽIA</Link></li>
              <li className="nav-item"><Link to="/privalomas" className="nav-link">PRIVALOMASIS</Link></li>
              <li className="nav-item"><Link to="/kasko" className="nav-link">KASKO</Link></li>
              <li className="nav-item"><Link to="/turtas" className="nav-link">TURTAS</Link></li>
              <li className="nav-item"><Link to="/nelaimingi" className="nav-link">NELAIMINGAS ATSITIKIMAS</Link></li>
              <li className="nav-item"><Link to="/civiline" className="nav-link">CIVILINĖ ATSAKOMYBĖ</Link></li>
              <li className="nav-item"><Link to="/kiti" className="nav-link">KITA ŽALA</Link></li>
            </ul>
          </div>
        </nav>




        {/* <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <NavbarBrand tag={Link} to="/"><img src={ivplogo} alt="logo" /></NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Pradžia</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar> */}
      </header>
    );
  }
}

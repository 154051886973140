import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import apiRequest from "../utils/apiRequest";
import DropzoneStyled from "./Dropzone";
import IvykioAplinkybes from "./IvykioAplinkybes";
import PranesanciojoInformacija from "./PranesancioInformacija";
import Patvirtinimas from "./Patvirtinimas";
import ListFiles from "./listFiles";

export default function Nelaimingi() {
  const navigate = useNavigate();
  const location = useLocation;
  const [isSaving, setSaving] = useState(false);
  const [sutikimas, setSutikimas] = useState();
  const [israsas, setIsrasas] = useState();
  const [error, setError] = useState({});
  const [dokumentai, setDokumentai] = useState([]);

  const onSubmit = (data, e) => {
    setSaving(true);
    if (dokumentai && dokumentai.length) {
      data.dokumentai = dokumentai;
    }
    if (israsas && israsas.dydis) {
      data.israsas = israsas;
    } else {
      toast.error("Išrašas yra būtinas");
      error.israsas = true;
      setError(error);
    }
    if (data.elPastasSutikimui || (sutikimas && sutikimas.dydis)) {
      data.sutikimas = sutikimas;
    } else {
      toast.error(
        "Sutikimas arba el. pašto adresas sustikimui gauti yra būtinas"
      );
      error.sutikimas = true;
      setError(error);
    }
    if (error.israsas || error.sutikimas) {
      setSaving(false);
      return;
    }

    return apiRequest("nelaimingi", data)
      .then(() => {
        setSaving(false);
        toast.success(
          "Dėkojame. Jūsų pranešimas yra sėkmingai pateiktas. Esant poreikiui patikslinti informaciją, atsakingas ekspertas su Jumis susisieks."
        );
        navigate("/");
      })
      .catch((e) => {
        setSaving(false);
        toast.error(e.message ? e.message : e);
      });
  };

  const handleDrop = () => {
    setSaving(true);
  };

  const handleDokumentaiUpload = (files) => {
    setDokumentai(files.concat(dokumentai));
    setSaving(false);
  };

  const handleIsrasasUpload = (failai) => {
    setIsrasas(failai[0]);
    error.israsas = false;
    setError(error);
    setSaving(false);
  };

  const handleSutikimasUpload = (failai) => {
    setSutikimas(failai[0]);
    error.sutikimas = false;
    setError(error);
    setSaving(false);
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useForm();
  const watchNukentejes = watch("arNukentejes", "ne");

  return (
    <React.Fragment>
      <div className="page-header">
        <h1>
          <FontAwesomeIcon icon={faThumbsDown} /> Nelaimingo atsitikimo draudimo
          įvykis
        </h1>
        <p>
          Prieš pradedami pildyti šį pranešimą įsitikinkite, kad jau turite
          pasirašytą sutikimą dėl asmens duomenų tvarkymo (sutikimų formas
          rasite{" "}
          <Link to={"/sutikimai"} target="_blank">
            čia
          </Link>
          ) ir išrašą iš medicinos dokumentų, nes jie yra būtini norint
          sėkmingai pateikti mums pranešimą apie įvykį.{" "}
        </p>
        <p>* pažymėti laukai yra privalomi</p>
      </div>
      <form className="form-30" onSubmit={handleSubmit(onSubmit)}>
        <PranesanciojoInformacija
          search={location.search}
          register={register}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
        />
        <hr />
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">
            * Draudimo liudijimo Nr.
          </label>
          <div className="col-sm-8">
            <input
              className="form-control"
              {...register("liudijimoNr", { required: true })}
            />
            <span>
              Atkreipiame dėmesį, kad atidžiai nurašytumėt teisingą poliso
              (liudijimo) numerį, kitaip žala negalės būti sėkmingai
              užregistruota.
            </span>
          </div>
        </div>
        <hr />
        <h5>Nukentėjusiojo (-ųjų) informacija</h5>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">
            * Ar esate nukentėjęs?
          </label>
          <div className="col-sm-8">
            <div className="btn-group btn-group-5">
              <label
                className={
                  watchNukentejes === "taip"
                    ? "btn btn-gray active"
                    : "btn btn-gray"
                }
              >
                <input
                  type="radio"
                  value="taip"
                  {...register("arNukentejes", { required: true })}
                  className="radio-button"
                />
                Taip
              </label>
              <label
                className={
                  watchNukentejes === "ne"
                    ? "btn btn-gray active"
                    : "btn btn-gray"
                }
              >
                <input
                  type="radio"
                  value="ne"
                  {...register("arNukentejes", { required: true })}
                  className="radio-button"
                />
                Ne
              </label>
              {errors.arNukentejes && (
                <span className="error">Reikia pasirinkti</span>
              )}
            </div>
          </div>
        </div>
        {watchNukentejes === "ne" && (
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              * Nukentėjusiojo vardas pavardė{" "}
            </label>
            <div className="col-sm-8">
              <input
                className={
                  errors.nukentejusioVardasPavarde
                    ? "form-control error"
                    : "form-control"
                }
                {...register("nukentejusioVardasPavarde", {
                  validate: (value) => value && watchNukentejes === "ne",
                })}
              />
              {errors.nukentejusioVardasPavarde && (
                <span className="error">Šis laukas yra privalomas</span>
              )}
            </div>
          </div>
        )}
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">
            Nukentėjusiojo asmens kodas
          </label>
          <div className="col-sm-8">
            <input
              className="form-control"
              {...register("nukentejusioAsmensKodas")}
            />
          </div>
        </div>
        {watchNukentejes === "ne" && (
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              Nukentėjusiojo (arba jo teisėto atstovo) telefono nr.
            </label>
            <div className="col-sm-8">
              <input
                className="form-control"
                type="tel"
                {...register("nukentejusioTelefonas")}
              />
            </div>
          </div>
        )}
        {watchNukentejes === "ne" && (
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              Nukentėjusiojo (arba jo teisėto atstovo) el. pašto adresas
            </label>
            <div className="col-sm-8">
              <input
                className="form-control"
                type="tel"
                {...register("nukentejusioElPastas", { required: true })}
              />
            </div>
          </div>
        )}
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">
            Nukentėjusiojo gyv. vieta (adresas)
          </label>
          <div className="col-sm-8">
            <input
              className="form-control"
              type="text"
              {...register("nukentejusioAdresas")}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">
            Kiti nukentėjusieji (vardas, pavardė, kontaktai)
          </label>
          <div className="col-sm-8">
            <textarea
              className="form-control"
              rows="5"
              {...register("nukentejusiuDuomenys")}
            />
          </div>
        </div>

        <hr />
        <h5>Informacija apie įvykį</h5>

        <div className="form-group row">
          <label className="col-sm-4 col-form-label">
            * Įvykio data ir laikas
          </label>
          <div className="col-sm-8">
            <div className="input-group">
              <input
                className={
                  errors.ivykioData ? "form-control error" : "form-control"
                }
                type="date"
                {...register("ivykioData", {
                  validate: {
                    required: (value) => value !== null && value !== "",
                    lessThanToday: (value) =>
                      value && new Date(value) <= new Date(),
                  },
                })}
              />
              <input
                className={
                  errors.ivykioLaikas ? "form-control error" : "form-control"
                }
                type="time"
                {...register("ivykioLaikas", { required: true })}
              />
            </div>
            {errors.ivykioData && errors.ivykioData.type === "required" && (
              <span className="error">Įvykio data yra būtina. </span>
            )}
            {errors.ivykioData &&
              errors.ivykioData.type === "lessThanToday" && (
                <span className="error">
                  Įvykis negalėjo nutikti ateityje.{" "}
                </span>
              )}
            {errors.ivykioLaikas && (
              <span className="error">Įvykio laikas yra būtinas. </span>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">
            * Įvykio vieta (adresas)
          </label>
          <div className="col-sm-8">
            <input
              className={
                errors.ivykioVieta ? "form-control error" : "form-control"
              }
              {...register("ivykioVieta", { required: true })}
            />
            {errors.ivykioVieta && (
              <span className="error">Šis laukas yra privalomas</span>
            )}
          </div>
        </div>
        <IvykioAplinkybes watch={watch} register={register} errors={errors} />
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">
            * Apibūdinkite sužalojimus (jei aktualu, įvardinkite, kurios kūno
            pusės)
          </label>
          <div className="col-sm-8">
            <textarea
              className={
                errors.suzalojimai ? "form-control error" : "form-control"
              }
              rows="5"
              {...register("suzalojimai", { required: true })}
            />
            {errors.suzalojimai && (
              <span className="error">Šis laukas yra privalomas</span>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">* Diagnozė</label>
          <div className="col-sm-8">
            <textarea
              className={
                errors.diagnoze ? "form-control error" : "form-control"
              }
              rows="5"
              {...register("diagnoze", { required: true })}
            />
            {errors.diagnoze && (
              <span className="error">Šis laukas yra privalomas</span>
            )}
          </div>
        </div>

        <hr />
        <h5>Susiję dokumentai</h5>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">
            * Sutikimas dėl asmens duomenų tvarkymo
          </label>
          <div className="col-sm-8">
            <DropzoneStyled
              name="sutikimas"
              error={error.sutikimas}
              handleDrop={handleDrop}
              handleUpload={handleSutikimasUpload}
              multiple={false}
              tekstas="Nutempkite sutikimo dokumentą čia arba paspauskite norėdami jį pasirinkti... <br /><i>(Čia galite prisegti tik vieną dokumentą. Esant poreikiui prisegti kelis failus, įkelkite likusius į skiltį “Kiti dokumentai”)</i>"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/x-csv"
            />
            <ListFiles failas={sutikimas} />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label"></label>
          <div className="col-sm-8">
            Nukentėjusiojo (arba jo teisėto atstovo) sutikimas mums (IVP
            Partners) yra būtinas dėl nukentėjusiojo asmens duomenų tvarkymo,
            siekiant registruoti ir administruoti žalą. Draudikui sutikimas yra
            būtinas, norint išmokėti išmoką. Standartinę sutikimo formą galite
            rasti Savitarnos svetainės{" "}
            <a href="https://savitarnaivp.lt" target="_blanc">
              savitarnaivp.lt
            </a>{" "}
            registruoto naudotojo <i>Dokumentai</i> skiltyje arba pasirinkite iš{" "}
            <Link to={"/sutikimai"} target="_blank">
              šio sąrašo
            </Link>{" "}
            pagal Jus draudžiančią draudimo bendrovę.
          </div>
        </div>

        {/* <div className="form-group row">
                    <label className="col-sm-4 col-form-label"></label>
                    <div className="col-sm-8">
                        <Link to={"/sutikimai"} className="btn btn-orange" target="_blank">Paspauskite čia</Link><span> jeigu norite gauti sutikimo dėl asmens duomenų tvarkymo formą.</span>
                    </div>
                </div> */}

        <div className="form-group row">
          <label className="col-sm-4 col-form-label">
            * Išrašas iš medicininių dokumentų
          </label>
          <div className="col-sm-8">
            <DropzoneStyled
              name="israsas"
              error={error.israsas}
              handleDrop={handleDrop}
              handleUpload={handleIsrasasUpload}
              multiple={false}
              tekstas="Nutempkite išrašo dokumentą čia arba paspauskite norėdami jį pasirinkti... <br /><i>(Čia galite prisegti tik vieną dokumentą. Esant poreikiui prisegti kelis failus, įkelkite likusius į skiltį “Kiti dokumentai”)</i>"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/x-csv"
            />
            <ListFiles failas={israsas} />
            {errors.israsas && (
              <span className="error">Išrašo dokumentas yra būtinas.</span>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Kiti dokumentai</label>
          <div className="col-sm-8">
            <DropzoneStyled
              name="dokumentai"
              handleDrop={handleDrop}
              handleUpload={handleDokumentaiUpload}
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/x-csv"
              multiple={true}
              failai={dokumentai}
              tekstas="Nutempkite kitus failus čia arba paspauskite norėdami failus pasirinkti..."
            />
            <ListFiles failai={dokumentai} />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12">
            Bendras prisegtų dokumentų maksimalus dydis – 500 MB. Leidžiami
            formatai - .pdf, .jpg, .jpeg, .gif, .png, .doc, .docx, .xls, .xlsx.
          </div>
        </div>

        <hr />
        <Patvirtinimas register={register} errors={errors} />
        <div className="form-group">
          <input
            type="submit"
            className="btn btn-submit btn-lg btn-block"
            value="Pateikti"
            disabled={isSaving}
          />
        </div>
      </form>
    </React.Fragment>
  );
}

import React from "react";
import { Link, useLocation } from "react-router-dom";
import ScrollToTopOnMount from "./scrollToTop";

export function Home(props) {
    let location = useLocation();

  //const displayName = Home.name;

  return (
    <div>
      <ScrollToTopOnMount />
      <div className="page-header">
        <h1>Praneškite apie įvykį</h1>
      </div>
      <div className="row" style={{ padding: "40px" }}>
        <div className="col-sm-3">Turite klausimų? Pasitarkime:</div>
        <div className="col-sm-3">
          +370 5 2197602
          <br />
          +370 5 2197603
        </div>
        <div className="col-sm-3">zalos@ivp.lt</div>
      </div>

      <div className="row">
        <div className="col-sm-6" style={{ marginBottom: "10px" }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Privalomojo draudimo įvykis</h5>
              <p>Pateikite informaciją apie įvykį</p>
              <Link
                to={"/privalomas" + location.search}
                className="btn btn-orange"
              >
                Pranešti apie įvykį
              </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-6" style={{ marginBottom: "10px" }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">KASKO draudimo įvykis</h5>
              <p>Pateikite informaciją apie įvykį</p>
              <Link
                to={"/kasko" + location.search}
                className="btn btn-orange"
              >
                Pranešti apie įvykį
              </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-6" style={{ marginBottom: "10px" }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Turto draudimo įvykis</h5>
              <p>Pateikite informaciją apie įvykį</p>
              <Link
                to={"/turtas" + location.search}
                className="btn btn-orange"
              >
                Pranešti apie įvykį
              </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-6" style={{ marginBottom: "10px" }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                Nelaimingų atsitikimų draudimo įvykis
              </h5>
              <p>Pateikite informaciją apie įvykį</p>
              <Link
                to={"/nelaimingi" + location.search}
                className="btn btn-orange"
              >
                Pranešti apie įvykį
              </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-6" style={{ marginBottom: "10px" }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                Civilinės atsakomybės draudimo rūšies įvykis
              </h5>
              <p>Pateikite informaciją apie įvykį</p>
              <Link
                to={"/civiline" + location.search}
                className="btn btn-orange"
              >
                Pranešti apie įvykį
              </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-6" style={{ marginBottom: "10px" }}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Kitos draudimo rūšies įvykis</h5>
              <p>Pateikite informaciją apie įvykį</p>
              <Link
                to={"/kiti" + location.search}
                className="btn btn-orange"
              >
                Pranešti apie įvykį
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

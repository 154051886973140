import React from 'react'

export default function IvykioAplinkybes(props) {
    const { register, errors } = props;

    return (
        <div className="form-group row">
            <label className="col-sm-4 col-form-label">* Įvykio priežastis, aplinkybės</label>
            <div className="col-sm-8">
                <textarea className={errors.ivykioAplinkybes ? "form-control error" : "form-control"} rows="5" {...register("ivykioAplinkybes", { required: true, minLength: 20 })} />
                {errors.ivykioAplinkybes?.type === "required" && <span className="error">Šis laukas yra privalomas. </span>}
                {errors.ivykioAplinkybes?.type === "minLength" && <span className="error">Turi būti ne mažiau kaip 20 raidžių.</span>}
            </div>
        </div>
    )
}

import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import Civiline from "./components/Civiline";
import Kasko from './components/Kasko';
import Keliones from "./components/Keliones";
import Kiti from "./components/Kiti";
import Nelaimingi from "./components/Nelaimingi";
import Privalomas from "./components/Privalomas";
import Turtas from "./components/Turtas";
import Sutikimai from './components/sutikimai';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './custom.css'

export default class App extends Component {
  static displayName = App.name;
  state = {};

  render () {
    return (
        <Layout>
          <ToastContainer
              position="bottom-right"
              autoClose={10000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
           <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/privalomas' element={<Privalomas />} />
            <Route path='/kasko' element={<Kasko />} />
            <Route path='/turtas' element={<Turtas />} />
            <Route path='/civiline' element={<Civiline />} />
            <Route path='/nelaimingi' element={<Nelaimingi />} />
            <Route path='/keliones' element={<Keliones />} />
            <Route path='/kiti' element={<Kiti />} />
            <Route path='/sutikimai' element={<Sutikimai />} />
           </Routes>
        </Layout>

    );
  }
}

import React, { useEffect, useState } from 'react'
import getUser from '../utils/getUser';

export default function PranesancioInformacija(props) {
    const { register, watch, setValue, getValues, errors, search } = props;
    const watchTurtasPriklauso = watch("turtasPriklauso", {});
    const [user, setUser] = useState();

    useEffect(() => {
        if (!user) {
            getUser(search).then(data => {
                if (data) {
                    setUser(data);
                    setValue("pranesancioPavardeVardas", data.pavadinimas);
                    setValue("pranesancioTelefonas", data.telefonas);
                    setValue("pranesancioElPastas", data.elPastas);
                    if (data.imones.length) {
                        setValue("imonesPavadinimas", data.imones[0].pavadinimas);
                    }
                }
            });
        }
        else if (!getValues("imonesPavadinimas")) {
            setValue("imonesPavadinimas", user.imones[0].pavadinimas);
        }
    }, [watchTurtasPriklauso, getValues, setValue, setUser, search, user]);

    return (
        <React.Fragment>
            <h5>Informacija apie pranešėją</h5>

            <div className="form-group row">
                <label className="col-lg-4 col-form-label">* Žala registruojama pagal draudimo sutartį</label>
                <div className="col-lg-8">
                    <div className="btn-group btn-group-5" >
                        <label className={watchTurtasPriklauso === "Juridinio asmens" ? "btn btn-gray active" : "btn btn-gray"}>
                            <input type="radio" value="Juridinio asmens" {...register("turtasPriklauso", { required: true })} className="radio-button" />Juridinio asmens
                        </label>
                        <label className={watchTurtasPriklauso === "Fizinio asmens" ? "btn btn-gray active" : "btn btn-gray"}>
                            <input type="radio" value="Fizinio asmens" {...register("turtasPriklauso", { required: true })} className="radio-button" />Fizinio asmens
                        </label>
                        {errors.turtasPriklauso && <span className="error">Reikia pasirinkti vieną iš dviejų</span>}
                    </div>
                </div>
            </div>

            {watchTurtasPriklauso === "Juridinio asmens" && <div className="form-group row">
                <label className="col-lg-4 col-form-label">* Įmonės pavadinimas</label>
                <div className="col-lg-8">
                    <input className={errors.imonesPavadinimas && watchTurtasPriklauso === "Juridinio asmens" ? "form-control error" : "form-control"} {...register("imonesPavadinimas", { validate: value => value && watchTurtasPriklauso === "Juridinio asmens" })} />
                    {errors.imonesPavadinimas && watchTurtasPriklauso === "Juridinio asmens" && <span className="error">Šis laukas yra privalomas</span>}
                </div>
            </div>}
            
            {watchTurtasPriklauso === "Fizinio asmens" && <div className="form-group row">
                <label className="col-lg-4 col-form-label">* Draudėjo (asmens, kurio vardu sudaryta draudimo sutartis) vardas pavardė</label>
                <div className="col-lg-8">
                    <input className={errors.pavardeVardas && watchTurtasPriklauso === "Fizinio asmens" ? "form-control error" : "form-control"} {...register("pavardeVardas", { validate: value => value && watchTurtasPriklauso === "Fizinio asmens" })} />
                    {errors.pavardeVardas && watchTurtasPriklauso === "Fizinio asmens" && <span className="error">Šis laukas yra privalomas</span>}
                </div>
            </div>}

            <div className="form-group row">
                <label className="col-lg-4 col-form-label">* Pranešėjo vardas pavardė</label>
                <div className="col-lg-8">
                    <input className={errors.pranesancioPavardeVardas ? "form-control error" : "form-control"} {...register("pranesancioPavardeVardas", { required: true })} />
                    {errors.pranesancioPavardeVardas && <span className="error">Šis laukas yra privalomas</span>}
                </div>
            </div>
            <div className="form-group row">
                <label className="col-lg-4 col-form-label">* Pranešėjo telefono numeris</label>
                <div className="col-lg-8">
                    <input type="tel" className={errors.pranesancioTelefonas ? "form-control error" : "form-control"} {...register("pranesancioTelefonas", { required: true })} />
                    {errors.pranesancioTelefonas && <span className="error">Šis laukas yra privalomas</span>}
                </div>
            </div>
            <div className="form-group row">
                <label className="col-lg-4 col-form-label">* Pranešėjo el. pašto adresas</label>
                <div className="col-lg-8">
                    <input type="email" className={errors.pranesancioElPastas ? "form-control error" : "form-control"} {...register("pranesancioElPastas", { required: true })} />
                    {errors.pranesancioElPastas && <span className="error">Šis laukas yra privalomas</span>}
                </div>
            </div>
        </React.Fragment>
    )
}

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PranesanciojoInformacija from './PranesancioInformacija';
import IvykioAplinkybes from './IvykioAplinkybes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarCrash } from '@fortawesome/free-solid-svg-icons';
import Patvirtinimas from './Patvirtinimas';
import { toast } from 'react-toastify';
import apiRequest from '../utils/apiRequest';
import { useLocation, useNavigate } from "react-router-dom";
import DropzoneStyled from "./Dropzone";
import ListFiles from './listFiles';

export default function Privalomas(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [failai, setFailai] = useState([]);
    const [isSaving, setSaving] = useState(false);

    const draudikai = [
        { key: -1, value: "-- Pasirinkite draudiką --" },
        { key: 36162, value: "BALCIA INSURANCE SE LIETUVOS FILIALAS" },
        { key: 2, value: "AAS BTA BALTIC INSURANCE COMPANY FILIALAS LIETUVOJE" },
        { key: 13077, value: "ADB COMPENSA VIENNA INSURANCE GROUP" },
        { key: 3122, value: "ERGO INSURANCE SE LIETUVOS FILIALAS" },
        { key: 3106, value: "ADB GJENSIDIGE" },
        { key: 3006, value: "IF P&C INSURANCE AS FILIALAS" },
        { key: 3005, value: "AB LIETUVOS DRAUDIMAS" },
        { key: -2, value: "Nėra sąraše" }
    ];

    const onSubmit = (data, e) => {
        setSaving(true);
        if (!watchNukentejoTransportas && !watchNukentejoTurtas && !watchNukentejoZmogus) {
            errors.watchNukentejoZmogus = true;
            e.preventDefault();
            return;
        }

        data.draudikasId = parseInt(data.draudikasId, 10);
        if (data.draudikasId > 0) {
            data.draudikoPavadinimas = draudikai.find(k => k.key === data.draudikasId)?.value;
        }

        data.dokumentai = failai;
        return apiRequest("privalomas", data).then(() => {
            setSaving(false);
            toast.success("Dėkojame. Jūsų pranešimas yra sėkmingai pateiktas. Esant poreikiui patikslinti informaciją, atsakingas ekspertas su Jumis susisieks.");
            navigate("/");
        }).catch((e) => {
            setSaving(false);
            toast.error(e.message ? e.message : e);
        });
    }

    const handleDokumentaiUpload = (files) => {
        setFailai(files.concat(failai));
        setSaving(false);
    };

    const handleDrop = () => {
        setSaving(true);
    }

    const { register, handleSubmit, watch, setValue, getValues, formState: {errors} } = useForm();
    const watchKaltininkas = watch("pranesejoTipas", null);
    const watchDeklaracija = watch("deklaracija", null);
    const watchPolicija = watch("policija", null);
    const watchVairuotojas = watch("vairuotojoTipas", null);
    const watchNukentejoTransportas = watch("nukentejoTransportas", null);
    const watchNukentejoTurtas = watch("nukentejoTurtas", null);
    const watchNukentejoZmogus = watch("nukentejoZmogus", null);
    const watchDraudikas = watch("draudikasId", null);

    return (
        <React.Fragment>
            <div className="page-header">
                <h1><FontAwesomeIcon icon={faCarCrash} /> Transporto priemonių valdytojo privalomojo draudimo įvykis</h1>
                <span className="remark">* pažymėti laukai yra privalomi</span>
            </div>
            <form className="form-30" onSubmit={handleSubmit(onSubmit)}>
                <PranesanciojoInformacija search={location.search} register={register} watch={watch} getValues={getValues} setValue={setValue} errors={errors} />
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Draudikas</label>
                    <div className="col-lg-8">
                        <select className={errors.draudikas ? "form-control error" : "form-control"} {...register("draudikasId")} >
                            {draudikai.map(d => <option key={d.key} value={d.key}>{d.value}</option>)}
                        </select>
                    </div>
                </div>
                {watchDraudikas === "-2" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Draudiko pavadinimas</label>
                    <div className="col-lg-8">
                        <input className={errors.draudikoPavadinimas ? "form-control error" : "form-control"} {...register("draudikoPavadinimas")} />
                    </div>
                </div>}
                <hr />
                <h5>Informacija apie įvykį</h5>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Jūs nukentėjote ar esate įvykio kaltininkas?</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchKaltininkas === "nukentejes" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="nukentejes" {...register("pranesejoTipas", { required: true })} className="radio-button" />
                                Nukentėjau</label>
                            <label className={watchKaltininkas === "kaltininkas" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="kaltininkas" {...register("pranesejoTipas", { required: true })} className="radio-button" />
                                Esu kaltininkas (-ė)</label>
                            {errors.pranesejoTipas && <span className="error">Būtina pasirinkti</span>}
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Eismo įvykio metu nukentėjo</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchNukentejoTransportas === true ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="checkbox" {...register("nukentejoTransportas", { validate: value => value === true || watchNukentejoTurtas === true || watchNukentejoZmogus === true })} id="nukentejoTransportas" className="radio-button" />
                                Transporto priemonė</label>
                            <label className={watchNukentejoTurtas === true ? "btn btn-gray active" : "btn btn-gray"} title="(pvz. kelio ženklas, tvora, atitvaras, pastatas)">
                                <input type="checkbox" {...register("nukentejoTurtas", {
                                    validate: value => watchNukentejoTransportas === true || value === true || watchNukentejoZmogus === true
                                })} id="nukentejoTurtas" className="radio-button" />
                                Kitas turtas</label>
                            <label className={watchNukentejoZmogus === true ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="checkbox" {...register("nukentejoZmogus", { validate: value => watchNukentejoTransportas === true || watchNukentejoTurtas === true || value === true })} id="nukentejoZmogus" className="radio-button" />
                                Žmogus</label>
                            {errors.nukentejoTurtas && errors.nukentejoTransportas && errors.nukentejoZmogus && <span className="error">Būtina pasirinkti</span>}
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* <strong>Kaltininko</strong> transporto priemonės valstybinis nr.</label>
                    <div className="col-lg-8">
                        <input className={errors.kaltininkoValstNr ? "form-control error" : "form-control"} {...register("kaltininkoValstNr", { required: true })} />
                        {errors.kaltininkoValstNr && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* <strong>Kaltininko</strong> transporto priemonės markė ir modelis</label>
                    <div className="col-lg-8">
                        <input className={errors.kaltininkoMarke ? "form-control error" : "form-control"} {...register("kaltininkoMarke", { required: true })} />
                        {errors.kaltininkoMarke && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                {watchNukentejoTransportas === true && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* <strong>Nukentėjusiojo</strong> transporto priemonės valstybinis nr.</label>
                    <div className="col-lg-8">
                        <input className={errors.nukentejusioValstNr ? "form-control error" : "form-control"} {...register("nukentejusioValstNr", { validate: value => value && watchNukentejoTransportas === true })} />
                        {errors.nukentejusioValstNr && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchNukentejoTransportas === true && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* <strong>Nukentėjusiojo</strong> transporto priemonės markė ir modelis</label>
                    <div className="col-lg-8">
                        <input className={errors.nukentejusioMarke ? "form-control error" : "form-control"} {...register("nukentejusioMarke", { validate: value => value && watchNukentejoTransportas === true })} />
                        {errors.nukentejusioMarke && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchNukentejoTransportas === true && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* <strong>Nukentėjusiojo</strong> transporto priemonės apgadinimai</label>
                    <div className="col-lg-8">
                        <textarea className={errors.nukentejusioTransportoApgadinimai ? "form-control error" : "form-control"} rows="5" {...register("nukentejusioTransportoApgadinimai", { validate: value => value && watchNukentejoTransportas === true })} />
                        {errors.nukentejusioTransportoApgadinimai && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchNukentejoTurtas === true && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* <strong>Nukentėjusiojo kito turto</strong> aprašymas ir apgadinimai</label>
                    <div className="col-lg-8">
                        <textarea className={errors.nukentejusioTurtoApgadinimai ? "form-control error" : "form-control"} rows="5" {...register("nukentejusioTurtoApgadinimai", { validate: value => value && watchNukentejoTurtas === true })} />
                        {errors.nukentejusioTurtoApgadinimai && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* <strong>Nukentėjusiojo</strong> duomenys (įmonės pavadinimas, įmonės kodas, vardas, pavardė, kontaktai)</label>
                    <div className="col-lg-8">
                        <textarea className={errors.nukentejusiuDuomenys ? "form-control error" : "form-control"} rows="5" {...register("nukentejusiuDuomenys", { required: true })} />
                        {errors.nukentejusiuDuomenys && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Įvykio data ir laikas</label>
                    <div className="col-lg-8">
                        <div className="input-group">
                            <input className={errors.ivykioData ? "form-control error" : "form-control"} type="date" {...register("ivykioData", { required: true })} />
                            <input className={errors.ivykioLaikas ? "form-control error" : "form-control"} type="time" {...register("ivykioLaikas", { required: true })} />
                        </div>
                        {(errors.ivykioData || errors.ivykioLaikas) && <span className="error">Šie laukai yra privalomi</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Įvykio vieta</label>
                    <div className="col-lg-8">
                        <input className={errors.ivykioVieta ? "form-control error" : "form-control"} {...register("ivykioVieta", { required: true })} />
                        {errors.ivykioVieta && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                <IvykioAplinkybes watch={watch} register={register} errors={errors} />
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Ar pildyta eismo įvykio deklaracija?</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchDeklaracija === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="taip" {...register("deklaracija", { required: true })} className="radio-button" />
                                Taip</label>
                            <label className={watchDeklaracija === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="ne" {...register("deklaracija", { required: true })} className="radio-button" />
                                Ne</label>
                            {errors.deklaracija && <span className="error">Būtina pasirinti</span>}
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Ar įvykis registruotas policijoje?</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5">
                            <label className={watchPolicija === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="taip" {...register("policija", { required: true })} className="radio-button" />
                                Taip</label>
                            <label className={watchPolicija === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="ne" {...register("policija", { required: true })} className="radio-button" />
                                Ne</label>
                            {errors.policija && <span className="error">Būtina pasirinkti</span>}
                        </div>
                    </div>
                </div>
                {watchPolicija === "taip" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Policijos padalinio pavadinimas (miestas/rajonas/apskritis ir įstaiga)</label>
                    <div className="col-lg-8">
                        <input className={errors.policijosPadalinys ? "form-control error" : "form-control"} {...register("policijosPadalinys", { validate: value => value && watchPolicija === "taip" })} />
                        {errors.policijosPadalinys && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Ar vairavote eismo įvykio metu?</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchVairuotojas === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="taip" {...register("vairuotojoTipas", { required: true })} className="radio-button" />
                                Taip</label>
                            <label className={watchVairuotojas === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="ne" {...register("vairuotojoTipas", { required: true })} className="radio-button" />
                                Ne</label>
                            <label className={watchVairuotojas === "nebuvoVairuotojo" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="nebuvoVairuotojo" {...register("vairuotojoTipas", { required: true })} className="radio-button" />
                                Nebuvo vairuotojo</label>
                            {errors.vairuotojoTipas && <span className="error">Būtina pasirinkti</span>}
                        </div>
                    </div>
                </div>
                {watchVairuotojas === "ne" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Vairuotojo vardas pavardė</label>
                    <div className="col-lg-8">
                        <input className={errors.vairuotojoPavardeVardas ? "form-control error" : "form-control"} {...register("vairuotojoPavardeVardas", { validate: value => value && watchVairuotojas === "ne" })} />
                        {errors.vairuotojoPavardeVardas && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchVairuotojas === "ne" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Vairuotojo asmens kodas</label>
                    <div className="col-lg-8">
                        <input className={errors.vairuotojoKodas ? "form-control error" : "form-control"} {...register("vairuotojoKodas", { validate: value => value && watchVairuotojas === "ne" })} />
                        {errors.vairuotojoKodas && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                <hr />
                <h5>Susiję dokumentai</h5>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">(pvz. eismo įvykio deklaracija, vairuotojo pažymėjimas, transporto priemonės registracijos liudijimas, nuotraukos)</label>
                    <div className="col-lg-8">
                        <DropzoneStyled
                            handleDrop={handleDrop}
                            handleUpload={handleDokumentaiUpload}
                            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/x-csv"
                            multiple={true}
                            tekstas="Nutempkite failus čia arba paspauskite norėdami failus pasirinkti..."
                        />
                        <ListFiles failai={failai} />
                        <div>Bendras prisegtų dokumentų maksimalus dydis – 500 MB. Leidžiami formatai - .pdf, .jpg, .jpeg, .gif, .png, .doc, .docx, .xls, .xlsx.</div>
                    </div>
                </div>
                <hr />
                <Patvirtinimas register={register} errors={errors} />
                <div className="form-group">
                    <input type="submit" className="btn btn-submit btn-lg btn-block" value="Pateikti" disabled={isSaving} />
                </div>
            </form>
        </React.Fragment>
    )
}

export default async function apiRequest(action, data, method = "POST") {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    const options = {
        method: method,
        headers,
        cache: "default"
    };

    if(data) {
        options.body = JSON.stringify(data);
    }

    const response = await fetch('api/' + action, options);
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    if (response.status === 204) {
        return;
    }
    const error = await response.json();
    console.log(error);
    throw(response.status, error);
}
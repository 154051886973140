import React from 'react';
import { useForm } from 'react-hook-form';
import PranesanciojoInformacija from './PranesancioInformacija';
import Patvirtinimas from './Patvirtinimas';

export default function Keliones(props) {
    const { register, handleSubmit, watch, getValues, setValue, errors } = useForm();
    const onSubmit = data => console.log(data);
    const watchDraudimoTipas = watch("draudimoTipas");
    const watchDraudejas = watch("draudejas");
    const watchBankas = watch("bankas");
    const watchTikIvp = watch("tikIvp");
    const watchTuriDraudimaKitur = watch("turiDraudimaKitur");

    return (
        <React.Fragment>
            <div className="page-header">
                <h1>Kelionių draudimo įvykis</h1>
                <span className="remark">* pažymėti laukai yra privalomi</span>
            </div>
            <form className="form-30" onSubmit={handleSubmit(onSubmit)}>
                <PranesanciojoInformacija search={props.location.search} register={register} watch={watch} getValues={getValues} setValue={setValue} errors={errors} />
                <hr />
                <h5>Pagrindiniai duomenys</h5>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Kokią žalą patyrėte kelionės metu?</label>
                    <div className="col-lg-8">
                        <select name="zalaKelioneje" id="zalaKelioneje" className="form-control">
                            <option value="1">Medicininės išlaidos ir repatriacija</option>
                            <option value="2">Asmens civilinė atsakomybė ir visos kitos atskirai neįvardintos rizikos</option>
                            <option value="3">Kelionės dokumentų praradimas</option>
                            <option value="4">Bagažo vėlavimas, praradimas ar sugadinimas</option>
                            <option value="5">Kelionės pakeitimai (vėlavimas, atšaukimai, jungties praradimas, neįvykdymas, atšaukimas)</option>
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Kelionės draudimo tipas</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" >
                            <label className={watchDraudimoTipas === "1" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input name="draudimoTipas" type="radio" value="1" ref={register({ required: true })} className="radio-button" />
                                Draudimo liudijimas</label>
                            <label className={watchDraudimoTipas === "2" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input name="draudimoTipas" type="radio" value="2" ref={register({ required: true })} className="radio-button" />
                                Banko kortelė</label>
                            {errors.draudimoTipas && <span className="error">Šis laukas yra privalomas</span>}
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Ar esate draudėjas?</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" data-toggle="buttons">
                            <label className={watchDraudejas === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input name="draudejas" type="radio" value="taip" ref={register({ required: true })} />
                                Taip</label>
                            <label className={watchDraudejas === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input name="draudejas" type="radio" value="ne" ref={register({ required: true })} />
                                Ne</label>
                            {errors.draudejas && <span className="error">Šis laukas yra privalomas</span>}
                        </div>
                    </div>
                </div>
                {watchDraudimoTipas === "1" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Draudimo liudijimo Nr.</label>
                    <div className="col-lg-8">
                        <input name="liudijimoNr" className="form-control" ref={register()} />
                    </div>
                </div>}
                {watchDraudimoTipas === "2" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Banko pavadinimas</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" data-toggle="buttons">
                            <label className={watchBankas === "1" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input name="bankas" type="radio" value="1" ref={register({ required: true })} />
                                SEB</label>
                            <label className={watchBankas === "2" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input name="bankas" type="radio" value="2" ref={register({ required: true })} />
                                Luminor</label>
                            {errors.bankas && <span className="error">Šis laukas yra privalomas</span>}
                        </div>
                    </div>
                </div>}
                {watchDraudimoTipas === "2" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Banko kortelės numeris</label>
                    <div className="col-lg-8">
                        <input name="kortelesNumeris" className="form-control" ref={register({ required: true })} />
                        {errors.kortelesNumeris && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                {watchDraudimoTipas === "2" && <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Banko kortelės savininko vardas pavardė</label>
                    <div className="col-lg-8">
                        <input name="kortelesSavininkas" className="form-control" ref={register({ required: true })} />
                        {errors.kortelesSavininkas && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>}
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Nukentėjusiojos vardas pavardė / įmonės pavadinimas</label>
                    <div className="col-lg-8">
                        <input name="pavadinimas" className="form-control" ref={register({ required: true })} />
                        {errors.pavadinimas && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Nukentėjusiojo asmens kodas / įmonės kodas</label>
                    <div className="col-lg-8">
                        <input name="kodas" className="form-control" type="text" ref={register()} />
                    </div>
                </div>

                <hr />
                <h5>Informacija apie įvykį</h5>

                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Įvykio data ir laikas</label>
                    <div className="col-lg-8">
                        <div className="input-group">
                            <input name="ivykioData" className="form-control" type="date" ref={register({ required: true })} />
                            <input name="ivykioLaikas" className="form-control" type="time" ref={register({ required: true })} />
                        </div>
                        {(errors.ivykioData || errors.ivykioLaikas) && <span className="error">Šie laukai yra privalomi</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Įvykio vieta</label>
                    <div className="col-lg-8">
                        <input name="ivykioVieta" className="form-control" ref={register({ required: true })} />
                        {errors.ivykioVieta && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Įvykio aplinkybės</label>
                    <div className="col-lg-8">
                        <textarea name="aplinkybes" className="form-control" rows="5" ref={register({ required: true })} />
                        {errors.aplinkybes && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Patvirtinu, kad dėl šio įvykio išlaidų kompensavimo kreipiuosi tik į IVP Partners</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" data-toggle="buttons">
                            <label className={watchTikIvp === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input name="tikIvp" type="radio" value="taip" ref={register({ required: true })} />
                                Taip</label>
                            <label className={watchTikIvp === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input name="tikIvp" type="radio" value="ne" ref={register({ required: true })} />
                                Ne</label>
                            {errors.tikIvp && <span className="error">Šis laukas yra privalomas</span>}
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Ar nukentėjęs asmuo įvykio metu turėjo kelionių medicininių išlaidų draudimą kitoje draudimo bendrovėje ar banke? (Pavyzdžiui, banko kreditinių kortelių kelionių draudimą, mobiliojo ryšio operatorių kelionių draudimą, kt.)</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5" data-toggle="buttons">
                            <label className={watchTuriDraudimaKitur === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input name="turiDraudimaKitur" type="radio" value="taip" ref={register({ required: true })} />
                                Taip</label>
                            <label className={watchTuriDraudimaKitur === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input name="turiDraudimaKitur" type="radio" value="ne" ref={register({ required: true })} />
                                Ne</label>
                            {errors.turiDraudimaKitur && <span className="error">Šis laukas yra privalomas</span>}
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Patirtų nuostolių suma, Eur</label>
                    <div className="col-lg-8">
                        <input name="nuostolis" className="form-control" ref={register({ required: true })} />
                        {errors.nuostolis && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                <hr />
                <h5>Sąskaita, į kurią pervesti draudimo išmoką</h5>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Banko sąskaitos numeris</label>
                    <div className="col-lg-8">
                        <input name="saskaita" className="form-control" ref={register()} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Banko pavadinimas</label>
                    <div className="col-lg-8">
                        <input name="bankas" className="form-control" ref={register()} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Sąskaitos savininko vardas pavardė / įmonės pavadinimas</label>
                    <div className="col-lg-8">
                        <input name="saskaitosSavininkas" className="form-control" ref={register()} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Sąskaitos savininko gimimo data</label>
                    <div className="col-lg-8">
                        <input type="date" name="gimimoData" className="form-control" ref={register()} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Sąskaitos savininko gyvenamosios vietos valstybė</label>
                    <div className="col-lg-8">
                        <select name="valstybe" id="valstybe" className="form-control">
                            <option value="">Rinkis</option>
                            <option value="LT">Lietuva</option>
                            <option value="LV">Latvija</option>
                            <option value="UK">Jungtinės karalystės</option>
                            <option value="RU">Rusija</option>
                        </select>
                    </div>
                </div>
                <hr />
                <h5>Susiję dokumentai</h5>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Dokumentai</label>
                    <div className="col-lg-8">
                        <input name="dokumentai" type="file" multiple className="btn btn-gray" />
                        <div>Bendras prisegtų dokumentų maksimalus dydis – 500 MB. Leidžiami formatai - .pdf, .jpg, .jpeg, .gif, .png, .doc, .docx, .xls, .xlsx.</div>
                    </div>
                </div>
                <hr />
                <Patvirtinimas register={register} errors={errors} />
                <div className="form-group">
                    <input type="submit" className="btn btn-submit btn-lg btn-block" value="Pateikti" />
                </div>
            </form>
        </React.Fragment>
    )
}

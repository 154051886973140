import React from 'react'

const renderFailai = (i) => (
    <li key={i.kelias}>{i.originalusPavadinimas}</li>
)

export default function ListFiles(props) {
    var {failai, failas} = {...props};
    if(failas) {
        failai = [failas];
    }
    return (
        failai && failai.length ? <ul>
            {failai.map(renderFailai)}
        </ul> : null
    )
}

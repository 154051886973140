import apiRequest from '../utils/apiRequest';
import qs from 'qs';

export default async function getUser(url) {
    if (url) {
        var query = qs.parse(url, {
            ignoreQueryPrefix: true
        });
        if (query.sender) {
            var user = await apiRequest("user/" + query.sender, null, "GET");
            return user;
        }
    }
}
import React from 'react';
import ScrollToTopOnMount from './scrollToTop';
import btaForma from '../assets/Sutikimas del asmens duomenu_BTA.pdf';
import compensaForma from '../assets/Sutikimas del asmens duomenu_Compensa.pdf';
import ergoForma from '../assets/Sutikimas del asmens duomenu_ERGO.pdf';
import gjensidigeForma from '../assets/Sutikimas del asmens duomenu_Gjensidige.pdf';
import ifForma from '../assets/Sutikimas del asmens duomenu_IF.pdf';
import ldForma from '../assets/Sutikimas del asmens duomenu_Lietuvos draudimas.pdf';

export default function Sutikimai() {
    return (
        <div>
            <ScrollToTopOnMount />
            <div className="page-header">
                <h1>Sutikimų dėl asmens duomenų tvarkymo formos</h1>
                <h4>(pasirinkite pagal Jus draudžiančią draudimo bendrovę)</h4>
            </div>
            <div className="row"><div className="col-sm-12"><p>Atsisiųskite jums reikalingo draudiko sutikimo dėl asmens duomenų tvarkymo formą. Paspaudus mygtuką Atidaryti forma bus atidaryra naujame naršyklės skirtuke. Nepamirškite išsaugoti šios formos savo kompiuteryje ar įrenginyje naršyklės pateiktomis priemonėmis. </p></div></div>
            <div className="row" style={{marginBottom: "30px"}}>
                <div className="col-sm-12">
                    Daugiau informacijos telefonu +370 5 2197602 arba el.paštu: zalos@ivp.lt
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6" style={{ marginBottom: "10px" }}>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">AAS BTA Baltic Insurance Company filialas Lietuvoje</h5>
                            <p>Sutikimo dėl asmens duomenų forma, kai Jūsų draudimo polisas sudarytas su draudiku AAS BTA Baltic Insurance Company filialas Lietuvoje</p>
                            <a href={btaForma} className="btn btn-orange">Atidaryti</a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6" style={{ marginBottom: "10px" }}>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Compensa Life Vienna Insurance Group SE</h5>
                            <p>Sutikimo dėl asmens duomenų forma, kai Jūsų draudimo polisas sudarytas su draudiku Compensa Life Vienna Insurance Group SE</p>
                            <a href={compensaForma} className="btn btn-orange">Atidaryti</a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6" style={{ marginBottom: "10px" }}>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">ERGO Insurance SE Lietuvos filialas</h5>
                            <p>Sutikimo dėl asmens duomenų forma, kai Jūsų draudimo polisas sudarytas su draudiku ERGO Insurance SE Lietuvos filialas</p>
                            <a href={ergoForma} className="btn btn-orange">Atidaryti</a>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6" style={{ marginBottom: "10px" }}>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Gjensidige, ADB</h5>
                            <p>Sutikimo dėl asmens duomenų forma, kai Jūsų draudimo polisas sudarytas su draudiku Gjensidige, ADB</p>
                            <a href={gjensidigeForma} className="btn btn-orange">Atidaryti</a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6" style={{ marginBottom: "10px" }}>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">If P&amp;C Insurance AS filialas</h5>
                            <p>Sutikimo dėl asmens duomenų forma, kai Jūsų draudimo polisas sudarytas su draudiku If P&amp;C Insurance AS filialas</p>
                            <a href={ifForma} className="btn btn-orange">Atidaryti</a>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6" style={{ marginBottom: "10px" }}>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Lietuvos draudimas, AB</h5>
                            <p>SSutikimo dėl asmens duomenų forma, kai Jūsų draudimo polisas sudarytas su draudiku Lietuvos draudimas, AB</p>
                            <a href={ldForma} className="btn btn-orange">Atidaryti</a>
                        </div>
                    </div>
                </div>
            </div>
            <div>Jeigu neradote Jums reikalingos formos, susisiekite su mumis.</div>
        </div>
    )
}

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PranesanciojoInformacija from './PranesancioInformacija';
import IvykioAplinkybes from './IvykioAplinkybes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import Patvirtinimas from './Patvirtinimas';
import { toast } from 'react-toastify';
import apiRequest from '../utils/apiRequest';
import { useLocation, useNavigate } from "react-router-dom";
import DropzoneStyled from "./Dropzone";
import ListFiles from './listFiles';

export default function Kiti() {
    const location = useLocation();
    const navigate = useNavigate();
    const [failai, setFailai] = useState([]);
    const [isSaving, setSaving] = useState(false);

    const onSubmit = (data, e) => {
        setSaving(true);
        data.dokumentai = failai;
        data.nuostoliai = parseFloat(data.nuostoliai)

        return apiRequest("kitas", data).then(() => {
            setSaving(false);
            toast.success("Dėkojame. Jūsų pranešimas yra sėkmingai pateiktas. Esant poreikiui patikslinti informaciją, atsakingas ekspertas su Jumis susisieks.");
            navigate("/");
        }).catch((e) => {
            setSaving(false);
            toast.error(e.message ? e.message : e);
        });
    }

    const handleDokumentaiUpload = (files) => {
        setFailai(files.concat(failai));
        setSaving(false);
    };

    const handleDrop = () => {
        setSaving(true);
    }

    const { register, handleSubmit, watch, setValue, getValues, formState: {errors} } = useForm();
    const watchDraudejas = watch("arDraudejas");

    return (
        <React.Fragment>
            <div className="page-header">
                <h1><FontAwesomeIcon icon={faBolt} /> Kitos draudimo rūšies įvykis</h1>
                <span className="remark">* pažymėti laukai yra privalomi</span>
            </div>
            <form className="form-30" onSubmit={handleSubmit(onSubmit)}>
                <PranesanciojoInformacija search={location.search} register={register} watch={watch} getValues={getValues} setValue={setValue} errors={errors} />
                <hr />
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Ar esate draudėjas?</label>
                    <div className="col-lg-8">
                        <div className="btn-group btn-group-5">
                            <label className={watchDraudejas === "taip" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="taip" {...register("arDraudejas", { required: true })} className="radio-button" />
                                Taip</label>
                            <label className={watchDraudejas === "ne" ? "btn btn-gray active" : "btn btn-gray"}>
                                <input type="radio" value="ne" {...register("arDraudejas", { required: true })} className="radio-button" />
                                Ne</label>
                            {errors.arDraudejas && <span className="error">Šis laukas yra privalomas</span>}
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Draudimo liudijimo Nr.</label>
                    <div className="col-lg-8">
                        <input className="form-control" {...register("liudijimoNr")} />
                    </div>
                </div>

                <hr />
                <h5>Informacija apie įvykį</h5>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Nukentėjęs objektas</label>
                    <div className="col-lg-8">
                        <input className={errors.nukentejesObjektas ? "form-control error" : "form-control"} {...register("nukentejesObjektas", { required: true })} />
                        {errors.nukentejesObjektas && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Įvykio data ir laikas</label>
                    <div className="col-lg-8">
                        <div className="input-group">
                            <input className={errors.ivykioData ? "form-control error" : "form-control"} type="date" {...register("ivykioData", { required: true })} />
                            <input className={errors.ivykioLaikas ? "form-control error" : "form-control"} type="time" {...register("ivykioLaikas", { required: true })} />
                        </div>
                        {(errors.ivykioData || errors.ivykioLaikas) && <span className="error">Šie laukai yra privalomi</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">* Įvykio vieta</label>
                    <div className="col-lg-8">
                        <input className={errors.ivykioVieta ? "form-control error" : "form-control"} {...register("ivykioVieta", { required: true })} />
                        {errors.ivykioVieta && <span className="error">Šis laukas yra privalomas</span>}
                    </div>
                </div>
                <IvykioAplinkybes watch={watch} register={register} errors={errors} />
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Preliminariai nuostolius vertinu, Eur</label>
                    <div className="col-lg-8">
                        <input type="number" className="form-control" {...register("nuostoliai")} />
                    </div>
                </div>

                <hr />
                <h5>Susiję dokumentai</h5>
                <div className="form-group row">
                    <label className="col-lg-4 col-form-label">Dokumentai</label>
                    <div className="col-lg-8">
                        <DropzoneStyled
                            handleDrop={handleDrop}
                            handleUpload={handleDokumentaiUpload}
                            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/x-csv"
                            multiple={true}
                            tekstas="Nutempkite failus čia arba paspauskite norėdami failus pasirinkti..."
                        />
                        <ListFiles failai={failai} />
                        <div>Bendras prisegtų dokumentų maksimalus dydis – 500 MB. Leidžiami formatai - .pdf, .jpg, .jpeg, .gif, .png, .doc, .docx, .xls, .xlsx.</div>
                    </div>
                </div>
                <hr />
                <Patvirtinimas register={register} errors={errors} />
                <div className="form-group">
                    <input type="submit" className="btn btn-submit btn-lg btn-block" value="Pateikti" disabled={isSaving} />
                </div>
            </form>
        </React.Fragment>
    )
}

import React from 'react'

export default function Patvirtinimas(props) {
    const { register, errors } = props;
    return (
        <>
            <h5>Patvirtinimas</h5>
            <div className="form-group row">
                <div className="col-lg-12">
                    <div className="form-check">
                    <label className={errors.duomenysPilni ? "form-check-label error" : "form-check-label"} htmlFor="duomenysPilni">
                        <input type="checkbox" id="duomenysPilni" className="form-check-input" {...register("duomenysPilni", { required: true })} /> 
                        * Patvirtinu, kad pateikta informacija yra išsami ir teisinga. Esu informuotas (-a), kad neišsamios ar klaidinančios informacijos pateikimas gali įtakoti draudiko išmokos dydį.</label>
                        {errors.duomenysPilni && <span className="error">  Patvirtinkite, kad informacija yra teisinga.</span>}                        
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label className="form-check-label">Esu informuotas, kad IVP Partners tvarkys ir perduos reikiamam draudikui mano asmens duomenis žalos reguliavimo tikslais (išsamiau apie IVP Partners asmens duomenų tvarkymą galite rasti <a href="https://www.ivp.lt/wp-content/uploads/2020/07/privatumo_politika.pdf" target="_blank" rel="noopener noreferrer">Privatumo politikoje</a> arba <a href="https://www.ivp.lt/wp-content/uploads/2018/12/Duomenu_subjekto_atmintine_v3.pdf" target="_blank" rel="noopener noreferrer">Duomenų subjekto atmintinėje</a> ).</label>
            </div>
        </>
    )
}

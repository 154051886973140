import React, { useCallback } from "react";
import { useDropzone } from 'react-dropzone'
import apiUpload from "../utils/upload";
import { toast } from 'react-toastify';

const baseStyle = {
    width: "auto",
    minHeight: 100,
    padding: 5,
    borderWidth: 2,
    borderColor: "#666",
    borderStyle: "dashed",
    borderRadius: 5,
    margin: "auto"
};
const activeStyle = {
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "#6c6",
    backgroundColor: "#eee"
};
const rejectStyle = {
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "#c66",
    backgroundColor: "#eee"
};

export const SAFE_FILE_TYPES = 'text/csv, application/vnd.ms-excel, text/x-csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/html, .msg, application/vnd.ms-outlook, application/vnd.oasis.opendocument.text, application/x-vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/rtf, text/plain, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip, audio/*, image/*, video/*';

export default function DropzoneStyled(props) {

    const onDrop = useCallback(async (acceptedFiles) => {

        if (props.handleDrop) props.handleDrop();

        var data = new FormData();
        acceptedFiles.forEach(f => {
            data.append("files", f);
        });
        var result = await apiUpload(data);

        if (result.length === 1) {
            toast.success(`Jūsų failas buvo sėkmingai įkeltas.`);
        } else if (result.length > 1) {
            toast.success(`Jūsų failai buvo sėkmingai įkelti.`);
        }

        if (props.handleUpload) props.handleUpload(result);
    }, [props])

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ onDrop })

    let styles = { ...baseStyle };
    if (props.error) {
        styles.borderColor = "red";
    }
    styles = isDragActive ? { ...styles, ...activeStyle } : styles;
    styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

    return (
        <div {...getRootProps()} style={styles}>
            <input {...getInputProps()} accept={SAFE_FILE_TYPES} multiple={props.multiple} />
            <div dangerouslySetInnerHTML={{ __html: props.tekstas }} />
        </div>
    );
};

